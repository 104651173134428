<template>
  <div>
    <div class="content_home">
      <b-carousel class="carousel_home"
        v-if="carousel.length > 0"
        v-model="slide" :interval="4000" controls indicators
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd">
        <b-carousel-slide v-for="item, index of carousel" :key="index" :img-src="item.link_banner">
          <template #img>
            <img v-if="item.link == 'ignorelink'" class="carousel_img" :src="item.link_banner" :alt="item.slug">
            <a v-else :href="item.link"><img class="carousel_img" :src="item.link_banner" :alt="item.slug"></a>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="site_limit">
      <h2>Selecionados para você</h2>
      <div class="element-docker bg-transparent mb-4">
        <Element :id="`item-${index}`" v-for="item,index in items" :key="index" :item="item" @details="details"></Element>
        <div class="spinner" v-show="loading"><b-spinner label="Carregando..."></b-spinner>Carregando...</div>
      </div>
    </div>
    <div class="block">
      <div class="site_limit">
        <div class="d-flex w-100 minihomebanner" v-if="minihomebanner != undefined">
            <img v-if="minihomebanner.link == 'ignorelink'" class="carousel_img" :src="minihomebanner.link_banner" :alt="minihomebanner.slug">
            <a v-else :href="minihomebanner.link"><img class="carousel_img" :src="minihomebanner.link_banner" :alt="minihomebanner.slug"></a>
        </div>
        <div class="d-flex w-100">
          <div class="newssletter">
            <inline-svg :src="require(`@/assets/svg/icon_car.svg`)" alt="car"></inline-svg>
            <h2>Receba nossas novidades</h2>
            <div class="form">
              <b-form-input class="input" name="name" type="text" placeholder="Digite seu e-nome" trim :state="StateName" v-model="newsletter.name"></b-form-input>
              <b-form-input class="input" name="email" type="email" placeholder="Digite seu e-mail" trim :state="StateEmail" v-model="newsletter.email"></b-form-input>
              <div v-if="newsletter.message != undefined" class="details" :class="(newsletter.received) ? 'success done' : 'fail wait'">{{ newsletter.message }}</div>
              <b-button v-else :disabled="StateAll" class="btn details" @click="AssignNewsLetter()">Cadastrar</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GoogleMap :address="google_maps" :reloadNumber="reloadNumberGoogle" />

  </div>
</template>

<script>
import axios from 'axios';
import Element from "@/components/Element";
import MercadoLivre from "@/components/tools/mercadoLivre";
import GoogleMap from "@/components/googleMap";
import emailValidator from '@/components/helpers/emailValidator';
import lenghtValidator from '@/components/helpers/lengthValidator';

export default {
  name: "Home",
  mixins: [ GoogleMap.mixin ],
  components: { Element, GoogleMap },
  props: {
    dataUser: Object,
    reloadCounter: Number,
  },
  data() {
    return {
      windWidth: 0,
      windWidth_Size: undefined,
      banners: [],
      minihomebanner: undefined,
      carousel: [],
      carousel_small: [],
      carousel_big: [],
      slide: 0,
      sliding: null,
      items: [],
      loading: false,
      connection: undefined,
      google_maps: undefined,
      carousel_height: undefined,
      carousel_max_height: undefined,
      newsletter: {
        name: undefined,
        email: undefined,
        received: false,
        message: undefined,
      },
    };
  },
  async mounted() {
    window.addEventListener("resize",() => {this.windWidth = window.innerWidth;},false);
    this.windWidth = window.innerWidth;
  },
  methods: {
    async AssignNewsLetter(){
      if(!this.StateAll) {
        var data = {
          name: this.newsletter.name,
          email: this.newsletter.email,
          collection: this.dataUser.options.datacollect.newsletter,
          store_id: process.env.VUE_APP_STORE_ID,
        };
        var url = `${process.env.VUE_APP_API_URL}/saveform`;
        await axios.post(url, data).then(() => {
          this.newsletter.name = "";
          this.newsletter.email = "";
          this.newsletter.received = true;
          this.newsletter.message = "Dados enviados com sucesso!";
        }).catch(() => {
          this.newsletter.received = false;
          this.newsletter.message = "Erro ao cadastrar os dados!";
          this.clearMessage();
        });
      }
    },
    clearMessage(time = 5000){
      setTimeout(() => {
        this.newsletter.message = undefined;
      }, time);
    },
    async carouselSize(windWidth) {
      var tmpwsize = (windWidth) < 768 ? 'small' : 'bigger';
      if(tmpwsize != this.windWidth_Size){
        this.windWidth_Size = tmpwsize;
        if (windWidth < 768) {
          //this.carousel_height = this.dataUser.options.carousel_sizes.small.height;
          //this.carousel_max_height = this.dataUser.options.carousel_sizes.small.max_height;
          this.carousel = this.carousel_small;
        } else {
          //this.carousel_height = this.dataUser.options.carousel_sizes.bigger.height;
          //this.carousel_max_height = this.dataUser.options.carousel_sizes.bigger.max_height;
          this.carousel = this.carousel_big;
        }
      }
    },
    async loadBanners() {
      this.carousel = [];
        var url = `${process.env.VUE_APP_API_URL}/banners/${process.env.VUE_APP_STORE_ID}`;
        await axios
          .get(url)
          .then((res) => {
            this.banners = res.data;
            this.dataUser.options.carousel.forEach((item) => {
              if(item.size == 'small'){
                this.carousel_small.push(this.banners.find((banner) => {
                  return banner.slug == item.slug;
                }));
              } else {
                this.carousel_big.push(this.banners.find((banner) => {
                  return banner.slug == item.slug;
                }));
              }
            });

              this.minihomebanner = this.banners.find((banner) => {
                return banner.slug == this.dataUser.options.minihomebanner;
              });
          })
          .catch((err) => {
            console.log(err);
          });

        this.carouselSize(this.windWidth);
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    async loadData() {
      this.items = [];
      this.loading = true;
      if(this.connection != undefined){
        this.dataUser.melihighlights.forEach(async (highlight) => {
          await this.connection.product(highlight.ml_product_id).then((res) => {
              this.items.push(res)
          });      
          if(this.dataUser.melihighlights.length == this.items.length){
            this.loading = false;
          }
        });
      }
    },
    details(item) {
      this.$router.push({ path: '/veiculos/'+ item.id });
    },
  },
  watch: {
    reloadCounter: {
      async handler() {
        if(this.dataUser != undefined) { 
          this.loadBanners() ;
          this.connection = await new MercadoLivre(this.dataUser.ml_user, this.dataUser.ml_default_category);
          this.loadData();
          this.showInGoogleMaps(this.dataUser.meliaddress.find(address => address.google_maps));
        }
      },
    },
    windWidth(w, oldw) {
      if(w != oldw && this.carousel.length > 0){
        this.carouselSize(w);
      }
    },
  },
  computed: {
    StateName() {
      return lenghtValidator.Max(this.newsletter.name, 3, 50);
    },
    StateEmail() {
      return emailValidator(this.newsletter.email);
    },
    StateAll() {
      return !(this.StateName && this.StateEmail);
    }
  },
};
</script>

<style lang="scss">

.block {
    background-color: #ffffff;
    padding: 40px 0;

    .d-flex {
      @media screen and (max-width: 991px) {
          justify-content: center;
      }
    }

    .minihomebanner img {
          width: 100%;
    }

    .site_limit {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      gap: 20px;

      @media screen and (max-width: 991px) {
          justify-content: center;
          flex-wrap: wrap;
      }
    }

    .newssletter {
      display: flex;
      background-color: var(--s-background-navbar);
      flex-direction: column;
      width: 100%;
      border-radius: 6px;
      padding: 30px 50px;
      gap: 20px;
      text-align: center;
      color: #ffffff;
      justify-content: center;

      .form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .details {
            cursor: pointer;
        }
        
        .details {
            background-color: var(--s-button-dark);
            border: none;
            padding: 12px;
            border-radius: 5px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;                    
        }

        .wait {
          cursor: wait;
        }
        .done {
          cursor: default;
        }

        .details:hover {
            background-color: var(--s-button-dark-hover);
            border: none;
        }
      }

      svg {
        align-self: center;
        width: 50px;
        margin-top: 24px;
      }
   }
}
</style>